import React from "react";
import BoldText from "./BoldText";

type Props = {
    spanContent?: string
    content?: string,
    boldContent?: string,
    widthPx?: string
    heightPx?: string
    marginLeft?: string
    marginRight?: string
    topTextPct?: string
    topMargin?: string
};

const SmallTagContainer = (props: Props) => {
    return (
        <div className="column-33 stop-margin" style={{padding:"0px", marginTop:props.topMargin, zIndex: 4}}>
            <div style={{"width": props.widthPx, "height": props.heightPx, position: "relative", marginLeft: props.marginLeft, marginRight: props.marginRight, }} className="border">
                <span className="dot-small-pink">{props.spanContent}</span>
                <div className="span-minor-text" style={{
                    zIndex: 2,
                    top: props.topTextPct,
                    left: "1%",
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto"
                }}>
                    <BoldText text={props.content} boldText={props.boldContent} />
                </div>
            </div>
        </div>
    );
};

export default SmallTagContainer;