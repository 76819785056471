import React, {useEffect} from 'react';
import '../App.css';
import axios from 'axios';


function Test() {

    useEffect(()=>{
        window.scrollTo(0, 0);
        axios.defaults.headers.common['Cookie'] = 'JSESSIONID=cs7IaNSQZZueG2IUyTNbZE9JtajxO08-tBw47MYx';
        axios.post('https://admin.staging.extasy.com/packages/table', '{"draw":1,"columns":[{"data":"translations","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"event","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}},{"data":"currency","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}},{"data":"state","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}},{"data":"id","name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}},{"data":"createdDate","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}}],"order":[{"column":5,"dir":"desc"}],"start":0,"length":10,"search":{"value":"","regex":false}}')
        // axios.post('https://admin.staging.extasy.com/packages/table', '{"draw":1,"columns":[{"data":"translations","name":"","searchable":true,"orderable":true,"search":{"value":"","regex":false}},{"data":"event","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}},{"data":"currency","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}},{"data":"state","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}},{"data":"id","name":"","searchable":false,"orderable":false,"search":{"value":"","regex":false}},{"data":"createdDate","name":"","searchable":false,"orderable":true,"search":{"value":"","regex":false}}],"order":[{"column":5,"dir":"desc"}],"start":0,"length":10,"search":{"value":"","regex":false}}')
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    },[])
  return (
      <>

      </>
  )
      ;
}

export default Test;
