import React, {ReactElement} from "react";

type Props = {
    content?: string,
    contentDetails?: string,
    element?: ReactElement
    background?: string,
    overlap?: string,
};

const BigTagContainer = (props: Props) => {
    return (
        <div className="column-33">
            <div style={{"width": "390px", "height": "600px", position: "relative"}} className="border">
                <div>
                {props.overlap &&
                    <span className="dot-big-purple"></span>
                }
                <img src={props.overlap} style={{
                    zIndex: 2,
                    top: "7%",
                    left: "25%",
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "215px"
                }}/>
                </div>

                {props.overlap &&
                <h2 className="tag-container-text" style={{
                    top: "44%",
                    left: "1%",
                    position: "absolute",
                    paddingLeft: "10px",
                    paddingRight: "10px"
                }}>
                    {props.content !== undefined ? props.content : props.element}
                </h2>
                }

                {props.overlap &&
                <p className="tag-container-text tag-container-details" style={{
                    top: "62%",
                    left: "1%",
                    position: "absolute",
                    paddingLeft: "12px",
                    paddingRight: "12px"
                }}>
                    {props.contentDetails !== undefined ? props.contentDetails : props.element}
                </p>
                }

            </div>
        </div>
    );
};

export default BigTagContainer;