import React from "react";

type Props = {
    text?: string
    class?: string,
};

const TitleSpan = (props: Props) => {
    return (
        <h2 className={`brushstroke-${props.class} section-inner-header`}>{props.text}</h2>
);
};

export default TitleSpan;