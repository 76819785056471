import React, {useEffect, useState} from "react";
import {UserProfile} from "../../services/model/UserProfile";
import {Box, Typography} from "@mui/material";
import {BillingAddress} from "../../services/model/Payments";
import {Button} from "react-bootstrap";

type Props = {
    billingAddress: BillingAddress,
    setExpand?: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

function BillingAddressStaticShower(props: Props) {

    return (
        <>
            <Typography variant="h5">Adresă de facturare</Typography>
                <div className="parent" style={{border:"solid, 2px", borderRadius:"30px"}}>
                    <div className="column-50-no-equal" style={{alignItems:"flex-start"}}>
                        {props.billingAddress && props.billingAddress.firstName !== '' ?
                            <>
                                <Typography>{props.billingAddress.firstName} {props.billingAddress.lastName} </Typography>
                                <Typography>{props.billingAddress.email}, {props.billingAddress.phone} </Typography>
                                <Typography>{props.billingAddress.address}, {props.billingAddress.city}, {props.billingAddress.county}, {props.billingAddress.country} </Typography>
                                <Typography>{props.billingAddress.postCode} </Typography>
                            </>
                            :
                            <></>
                        }

                    </div>
                    <div className="column-50-no-equal">
                        <Button className="button-just-text3" onClick={() => {props.setExpand && props.setExpand(true)}}>
                            {props.billingAddress && props.billingAddress.firstName !== '' ?
                                    <Typography>Editează</Typography>
                                :
                                    <Typography>Adaugă</Typography>
                            }
                        </Button>
                    </div>
                </div>
        </>
);
}

export default BillingAddressStaticShower;
