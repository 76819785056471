import React, {MouseEventHandler, ReactElement} from "react";

type Props = {
    content?: string,
    element?: ReactElement
    background?: string,
    overlap?: string,
    width?: string,
    height?: string,
    topPercent?: string
    bottomElement?: ReactElement
    onClick?: MouseEventHandler
};

const DynamicBigTagContainer = (props: Props) => {
    return (
        <div className="column-50-no-equal">
            <div style={{"width": props.width, "height": props.height, position: "relative"}} className={props.onClick ? "clickable border" : "border"} onClick={props.onClick}>
                <div>
                    {props.overlap &&
                        <span className="subscription-dot-big-purple"></span>
                    }
                    <img src={props.overlap} style={{
                        zIndex: 2,
                        top: "17%",
                        left: "16%",
                        position: "absolute",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "215px"
                    }}/>
                </div>

                <span className="span-minor-text" style={{
                    zIndex: 2,
                    top: props.topPercent,
                    left: "1%",
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto"
                }}>
                  {props.content !== undefined ? props.content : props.element}
                </span>
                <span className="span-minor-text" style={{
                    zIndex: 2,
                    left: "1%",
                    bottom: "0%",
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width:"100%", textAlign:"center"
                }}>
                    {props.bottomElement}
                </span>
            </div>
        </div>
);
};

export default DynamicBigTagContainer;