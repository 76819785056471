import React from "react";

type Props = {
    widthPx?: string
    heightPx?: string
    imgSrc?: string
};

const PathTagContainer = (props: Props) => {
    return (
        <div className="column-33 image-container">
                <span style={{
                    zIndex: 2,
                }}>
                  <img src={props.imgSrc} className="rotate90"/>
                </span>
            </div>
    );
};

export default PathTagContainer;