import React, {MouseEventHandler, useState} from 'react';
import '../App.css';
import explorer from "../assets/subscriptions/explorer.png";
import clubMember from "../assets/subscriptions/club_member.png";
import DynamicBigTagContainer from "../core/components/DynamicBigTagContainer";
import {Button} from "react-bootstrap";
import {subscribe} from "../services/userService";
import {SubscriptionModels} from "../services/model/SubscriptionModels";
import Overlay from "../core/components/overlay/Overlay";
import reading from '../assets/cefacem/reading_new.png';
import {useNavigate} from "react-router-dom";

function Subscription() {
    const navigate = useNavigate();

    const [montlhy, setMonthly] = useState(true);
    const [annual, setAnnual] = useState(false);

    function setPayAnually() {
        setMonthly(false);
        setAnnual(true);
    }

    function setPayMonthly() {
        setMonthly(true);
        setAnnual(false);
    }
    const requestExplorerSubscription : MouseEventHandler = function (){
        requestSubscription("EXPLORER");
    }

    const requestClubSubscription: MouseEventHandler = function () {
        requestSubscription("CLUB_MEMBER");
    }

    const requestSubscription = async (name: string)=> {
        let request : SubscriptionModels = {
            annually: annual,
            monthly: montlhy,
            type: name
        }
        await subscribe(request);
        toggleOverlay();
    }

    const [isOpen, setIsOpen] = useState(false);

    const toggleOverlay = () => {
        if (isOpen) {
            setIsOpen(!isOpen);
            navigate("/books");
        }
        setIsOpen(!isOpen);
    };

    return (
        <>

            <div className="App">
                <div id="wave" className="parent top-section div-with-background">
                    <div className="small-container ">
                        <br/><h2>Alege unul dintre abonamentele noastre</h2>
                    </div>
                </div>
                <div className="gradient-wave-bg"></div>

                <div className="App-body fauna-one-regular">
                    <div className="parent">
                        <div className="column-50-no-equal">
                            <Button className={montlhy ? "button-just-text3 active" : "button-just-text3"}
                                    onClick={setPayMonthly}>
                                Plată lunară
                            </Button>
                        </div>
                        <div className="column-50-no-equal">
                            <Button className={annual ? "button-just-text3 active" : "button-just-text3"}
                                    onClick={setPayAnually}>
                                Plată anuală
                            </Button>
                        </div>
                    </div>
                    <div style={{"display": "flex", "alignItems": "center"}} className="parent">

                        <DynamicBigTagContainer
                            element={<>
                                <ul style={{textAlign: "left"}}>
                                    <li>Acces nelimitat la o bibliotecă valoroasă, cu sute de cărți atent alese</li>
                                    <li>Acces la întrebările menite să susțină citirea interactivă</li>
                                    <li>Acces la activitățile inspirate din cărți</li>
                                    <li>Acces la articole bazate pe studii științifice și ghiduri practice</li>
                                    <li>Descoperă cărți după autor, categorie, tema</li>
                                    <li>Crează o listă de favorite</li>
                                    <li>Descoperă sugestii în funcție de alte cărți</li>
                                </ul>

                            </>}
                            bottomElement={
                                montlhy ?
                                    <div>
                                        <h5><s style={{color: "grey"}}>30 RON / lună</s></h5>
                                        <h3><strong>25 RON / lună</strong></h3>
                                    </div>
                                    :
                                    <div>
                                        <h5><s style={{color: "grey"}}>360 RON / an</s></h5>
                                        <h3><strong>280 RON / an</strong></h3>
                                    </div>

                            }
                            overlap={explorer}
                            width="330px"
                            height="800px"
                            topPercent="31%"
                            onClick={requestExplorerSubscription}
                        />

                        <DynamicBigTagContainer
                            element={<>
                                <ul style={{textAlign: "left"}}>
                                    <li>Toate beneficiile EXPLORER</li>
                                </ul>
                                <span>+</span>
                                <ul style={{textAlign: "left"}}>
                                    <li><strong>Iei acasă 4 cărți lunar</strong></li>
                                    <li>Plan de lectură creat special pentru copilul tău</li>
                                </ul>
                            </>}
                            bottomElement={
                                montlhy ?
                                    <div>
                                        <h5>Pachetul start aventură</h5>
                                        <h5><s style={{color: "grey"}}>220 RON</s></h5>
                                        <h3><strong>180 RON,</strong></h3>
                                        <h5>iar apoi</h5>
                                        <h5><s style={{color: "grey"}}>30 RON / lună</s></h5>
                                        <h3><strong>20 RON / lună</strong></h3>
                                    </div>
                                    :
                                    <div>
                                        <h5><s style={{color: "grey"}}>580 RON / an</s></h5>
                                        <h3><strong>400 RON / an</strong></h3>
                                    </div>
                            }
                            overlap={clubMember}
                            width="330px"
                            height="800px"
                            topPercent="31%"
                            onClick={requestClubSubscription}/>
                    </div>

                    <Overlay isOpen={isOpen} onClose={toggleOverlay}>
                        <img src={reading} style={{
                            zIndex: 2,
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "215px"
                        }}/>
                        <h4>Vă mulțumim pentru interesul acordat. Muncim din greu pentru a finaliza platforma și pentru
                            a vă oferi o experientă minunată. Vă vom contacta de îndată ce vom da drumul la
                            înscrieri.</h4>
                        <button
                            className="button-just-text3 white"
                            type="button"
                            onClick={toggleOverlay}
                        >Continuă explorarea
                        </button>
                    </Overlay>
                </div>
                <div className="gradient-wave-bg flip"></div>
            </div>

        </>
    )
        ;
}

export default Subscription;
