import {Fragment, MouseEventHandler, ReactElement} from "react";
import "./Overlay.css";

type Props = {
    isOpen?: boolean
    onClose?: MouseEventHandler
    children?: ReactElement[]
};
const Overlay = (props: Props) => {
    return (
        <Fragment>
            {props.isOpen && (
                <div className="overlay">
                    <div className="overlay__background" onClick={props.onClose} />
                    <div className="overlay__container">
                        <div className="overlay__controls">

                        </div>
                        {props.children}

                    </div>
                </div>
            )}
        </Fragment>
    );
}

export default Overlay;