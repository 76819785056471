import '../App.css';
import {Box, Grid, Typography} from '@mui/material';
import {Link} from "react-router-dom";
import {ExchangeSchedules, ReadingGroupInExchange} from "../services/model/GroupExchangeResponse";
import ExchangeDateElement from "./ExchangeDateElement";
import {Button} from "react-bootstrap";
import React, {useCallback, useEffect} from "react";
import {setGroupToStatus} from "../services/teacherService";
import {ExchangeStatus} from "../services/model/ExchangeStatus";

type Props = {
    group: ReadingGroupInExchange,
    exchangeSchedules: ExchangeSchedules
}

const ReadingGroupExchangeElement = (props : Props) => {

    const [updated, setUpdated] = React.useState(false);
    useEffect(() => {
        setUpdated(false);
    }, [updated])

    function handlePreviousGroupStatusNotDelivered() {
        setGroupToStatus(props.exchangeSchedules.previousSchedule.id, props.group.id, 'NOT_DELIVERED_YET').then(value => {
            setGroupToStatus(props.exchangeSchedules.currentSchedule.id, props.group.id, 'NOT_RECEIVED').then(value1 => {
                props.group.previousUser.status = 'NOT_DELIVERED_YET';
                props.group.currentUser.status = 'NOT_RECEIVED';
                setUpdated(true)
            });
        });
    }

    function handleSuccessExchange() {
        if (props.exchangeSchedules.previousSchedule != undefined && props.exchangeSchedules.previousSchedule != null) {
            setGroupToStatus(props.exchangeSchedules.previousSchedule.id, props.group.id, 'DELIVERED').then(value => {
                setGroupToStatus(props.exchangeSchedules.currentSchedule.id, props.group.id, 'RECEIVED').then(value1 => {
                    props.group.previousUser.status = 'DELIVERED';
                    props.group.currentUser.status = 'RECEIVED';
                    setUpdated(true)}
                )});
        } else {
            setGroupToStatus(props.exchangeSchedules.currentSchedule.id, props.group.id, 'RECEIVED').then(value => {
                props.group.currentUser.status = 'RECEIVED';
                setUpdated(true)
            });
        }
    }

    return (
        <Box id="sized-container" style={{marginBottom: "10px"}} border={4}
             borderColor={props.group.color} borderRadius="15px">
            <Box display="flex" bgcolor={props.group.color} marginBottom="5px">
                <div className="parent">
                    <div className="column-33" style={{alignItems:"self-start"}}>
                                              <span className="span-bold-text" style={{
                                                  color: "white",
                                                  padding: "5px",
                                                  textAlign: "left"
                                              }}>
                                                  { (props.exchangeSchedules.previousSchedule !== null && props.exchangeSchedules.previousSchedule !== undefined) ?
                                                        <ExchangeDateElement
                                                            startTime={props.exchangeSchedules.previousSchedule.startTimestamp}
                                                            endTime={props.exchangeSchedules.previousSchedule.endTimestamp}
                                                            user={props.group.previousUser}/>
                                                      :
                                                      <></>
                                                  }
                                                  { (props.exchangeSchedules.currentSchedule !== null && props.exchangeSchedules.currentSchedule !== undefined) ?
                                                      <ExchangeDateElement
                                                            startTime={props.exchangeSchedules.currentSchedule.startTimestamp}
                                                            endTime={props.exchangeSchedules.currentSchedule.endTimestamp}
                                                            user={props.group.currentUser}/>
                                                      :
                                                      <></>
                                                  }
                                                  { (props.exchangeSchedules.nextSchedule !== null && props.exchangeSchedules.nextSchedule !== undefined) ?
                                                        <ExchangeDateElement
                                                            startTime={props.exchangeSchedules.nextSchedule.startTimestamp}
                                                            endTime={props.exchangeSchedules.nextSchedule.endTimestamp}
                                                            user={props.group.nextUser}/>
                                                      :
                                                      <></>
                                                  }
                                      </span>

                    </div>
                    <div className="column-33" style={{flexDirection:"row", justifyContent: "center"}}>
                        {
                            props.group.currentUser.status === 'RECEIVED' ?
                                <></>
                                :
                                <>
                                    {(props.exchangeSchedules.previousSchedule !== null && props.exchangeSchedules.previousSchedule !== undefined) ?
                                        <Button className="button-contact" style={{margin:"10px"}} onClick={() => { handlePreviousGroupStatusNotDelivered()}}>
                                            <Typography variant="body1">Cărți lipsă</Typography>
                                        </Button>
                                        :
                                        <></>
                                    }
                                    {(props.exchangeSchedules.currentSchedule !== null && props.exchangeSchedules.currentSchedule !== undefined) ?
                                        <Button className="button-contact"  style={{margin:"10px"}} onClick={() => { handleSuccessExchange()}}>
                                            <Typography variant="body1">Schimbat cu succes</Typography>
                                        </Button>
                                        :
                                        <></>
                                    }
                                </>
                        }

                    </div>
                    <div className="column-33">
                        <span className="span-bold-text" style={{
                            color: "white",
                            padding: "5px",
                            textAlign: "right"
                        }}>
                            {props.group.currentUser.status === 'RECEIVED' ? <strong>Pachet primit</strong> : <></>}
                            {props.group.currentUser.status === 'NOT_RECEIVED' ? <strong>Pachet lipsă</strong> : <></>}
                        </span>
                    </div>
                </div>
            </Box>
            <Grid container spacing={2}>
                {props.group?.books
                    .map((book, index1) => {
                        return (
                                <Grid item xs={6} sm={6} md={3} lg={3} >
                                    <Box>
                                        <div>
                                            <Link to={`/books/details/` + book.slug}>
                                                {book.photoUrl ? (
                                                    <Box key={book.photoUrl} sx={{
                                                        marginBottom: 2,
                                                        filter: "drop-shadow(" + props.group?.color + "60 20px 30px 10px)"
                                                    }}>
                                                        <div style={{ position: "relative", display: "inline-block"}}>

                                                            <span className="plan-dot" style={{backgroundColor: props.group?.color}}/>

                                                            <img className='image_wrapper bookInPlan' src={book.photoUrl}
                                                                 alt={book.name}/>
                                                        </div>
                                                    </Box>

                                                ) : (
                                                    <Typography variant="body2">No photos available</Typography>
                                                )}
                                            </Link>
                                            <Typography variant="h6" gutterBottom>{book.name}</Typography>
                                            <div className="overlay overlay_2">
                                                <h5><strong>Vezi detalii</strong></h5>
                                            </div>
                                        </div>
                                    </Box>
                                </Grid>
                            )
                        }
                    )}
            </Grid>
        </Box>
    );
}

export default ReadingGroupExchangeElement;