import {RouteType} from "./config";
import App from "../pages/App";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import Books from "../pages/Books";
import BookDetails from "../pages/BookDetails";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Subscription from "../pages/Subscription";
import BookClubs from "../pages/BookClubs";
import ContactUs from "../pages/ContactUs";
import UserReadingPlan from "../pages/UserReadingPlan";
import ForgotPassword from "../pages/ForgotPassword";
import MyAccount from "../pages/myaccount/MyAccount";
import TeacherGroups from "../pages/TeacherGroups";
import GroupDetails from "../pages/GroupDetails";
import Test from "../pages/Test";


const appRoutes: RouteType[] = [
  {
    path: "/",
    index: true,
    element: <App />,
    state: "RootApp"
  },
  {
    path: "/terms",
    element: <Terms />,
    state: "terms-conditions",
  },
  {
    path: "/test",
    element: <Test />,
    state: "terms-conditions",
  },
  {
    path: "/privacy",
    element: <Privacy />,
    state: "privacy-policy",
  },
  {
    path: "/my-plan",
    element: <UserReadingPlan />,
    state: "my-reading-plan",
  },
  {
    path: "/books/details/:slug",
    element: <BookDetails />,
    state: "books-details",
  },
  // {
  //   path: "/books-internal-june-2024",
  //   element: <BookDetailsHardcoded />,
  //   state: "books-hardcoded",
  // },
  {
    path: "/books",
    element: <Books />,
    state: "books",
  },
  {
    path: "/bookClubs",
    element: <BookClubs />,
    state: "bookClubs",
  },
  {
    path: "/teacher-groups",
    element: <TeacherGroups />,
    state: "teacher-groups",
  },
  {
    path: "/group-details/:groupId",
    element: <GroupDetails />,
    state: "group-details",
  },
  {
    path: "/contactUs",
    element: <ContactUs />,
    state: "contactUs",
  },
  // {
  //   path: "/books/list",
  //   element: <BooksHardcoded />,
  //   state: "booksHardcoded",
  // },
  {
    path: "/books/:filter/:slug",
    element: <div><Books /></div>,
    state: "books",
  },
  {
    path: "/login",
    element: <Login />,
    state: "Login",
  },
  {
    path: "/my-account",
    element: <MyAccount />,
    state: "MyAccount",
  },
  {
    path: "/register",
    element: <Register />,
    state: "Register",
  },
  {
    path: "/reset-password",
    element: <ForgotPassword />,
    state: "ForgotPassword",
  },
  {
    path: "/subscription",
    element: <Subscription />,
    state: "Subscription",
  }
 ];

export default appRoutes;