import '../App.css';
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {contactUs} from "../services/userService";
import {ContactUsRequest} from "../services/model/ContactUsRequest";
import {useNavigate} from "react-router-dom";
import List from "@mui/material/List";
import StarIcon from "@mui/icons-material/Star";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

function ContactUs() {
    const navigate = useNavigate();
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    const [validated, setValidated] = useState(false);
    const [contactUsRequest, setContactUsRequest] = useState<ContactUsRequest>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        city: '',
        kindergarten: '',
        role: ''
    });
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        // Redirect or perform other actions upon successful action
        navigate("/books");
        window.location.reload();
    }
    const handleShow = () => setShow(true);


    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        setContactUsRequest({
            ...contactUsRequest,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = (event: FormEvent) => {
        const form = event.currentTarget;
        // @ts-ignore
        if (form.checkValidity() === false) {
        } else {
            contactUs(contactUsRequest).then(() => handleShow());
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    return (
        <>
        <div className="App">
            <div id="wave" className="parent top-section div-with-background">
                <div>
                    <h2>Vreau un club de lectură în gradinița mea</h2>
                    <List>
                        <ListItem disablePadding>
                            <StarIcon/>
                            <ListItemText style={{paddingLeft: "7px"}}
                                          primary="După ce completezi acest formular, noi vom contacta un reprezentat al grădiniței tale pentru a face un parteneriat."/>
                        </ListItem>
                        <ListItem disablePadding>
                            <StarIcon/>
                            <ListItemText style={{paddingLeft: "7px"}}
                                          primary="Tot ce trebuie să faci tu este să completezi formularul, de resul, ne ocupăm noi!"/>
                        </ListItem>
                    </List>
                </div>
            </div>

            <div className="parent top-section div-with-background">
                <div className="border align-left"
                     style={{marginTop: '10px', color: "black", fontSize: "initial", minWidth: "360px"}}>
                    <Form id="contact-us-form" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Row className="g-2">
                                <Col md>
                                    <Form.Label>
                                        Prenume *
                                    </Form.Label>
                                    <Form.Control
                                        type="input"
                                        name="firstName"
                                        placeholder="Prenume"
                                        onChange={handleChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Te rugăm să introduci prenumele.
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md>
                                    <Form.Label>
                                        Nume *
                                    </Form.Label>
                                    <Form.Control
                                        type="input"
                                        name="lastName"
                                        placeholder="Nume"
                                        onChange={handleChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Te rugăm să introduci numele.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                Email *
                            </Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email"
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci un email valid.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                Telefon *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="phone"
                                placeholder="Telefon"
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci un număr de telefon valid.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                Oraș *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="city"
                                placeholder="Oraș"
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci orașul.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                Grădiniță *
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="kindergarten"
                                placeholder="Grădiniță"
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci numele grădiniței.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                Care este rolul tău in grădiniță?
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="role"
                                placeholder="Rol"
                                onChange={handleChange}
                            />
                            <Form.Text className="text-muted">
                                Exemplu: părinte, administrator, educator, etc.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group style={{textAlign: 'center'}}>
                            <Button type="submit" className="button button-contact">
                                Trimite
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </div>
            <div>
            </div>
            <div className="gradient-wave-bg"></div>
            <Modal
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Mulțumim pentru interesul acordat</Modal.Title>
                </Modal.Header>
                <Modal.Body>Vei fi contactat curând de către unul dintre colegii noștri pentru a stabili toate detaliile pentru a aduce cluburile de lectură RaiseAReader în grădinița ta!</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default ContactUs;
