import React, {useState} from "react";
import {UserProfile} from "../../services/model/UserProfile";
import {Typography} from "@mui/material";
import {BillingAddress, PaymentMethod} from "../../services/model/Payments";
import {Button, Modal} from "react-bootstrap";
import {FcCheckmark} from "react-icons/fc";
import {PiExclamationMarkBold} from "react-icons/pi";
import {addNewCardRequest, deleteCardById, setMainCard} from "../../services/paymentsService";
import CardDetails from "./CardDetails";
import {getBillingAddress} from "../../services/userService";
import BillingAddressHandler from "./BillingAddressHandler";

type Props = {
    cards?: PaymentMethod,
    userDetails: UserProfile,
    billingAddress: BillingAddress,
    update: boolean,
    setUpdate?: (value: (((prevState: boolean) => boolean) | boolean)) => void
    expanded?: string | false
}

function UserCardsShower(props: Props) {

    const [loading, setLoading] = useState(false);
    const [showBillingModal, setShowBillingModal] = useState(false);

    function deleteCard(id: string) {
        deleteCardById(id).then(() => {
            if (props.setUpdate) {
                props.setUpdate(true);
            }
        });
    }
    const handleClose = () => {
        setShowBillingModal(false);
    }
    function getUrl() {
        if (!window.location.origin){
            // For IE
            // @ts-ignore
            window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        }

        return  window.location.origin + window.location.pathname;
    }
    function addNewCard() {
        setLoading(true);
        getBillingAddress().then(value => {
            addNewCardRequest({
                redirectUrl: getUrl() + '?openModalPaymentMethods=true&expandedPrev=' + props.expanded,
                view: "DESKTOP"
            }).then(value => window.location.href = value.data.formUrl)
                .catch(() => {
                    alert("Eroare la adăugarea cardului");
                    setLoading(false);
                })
        }).catch((error) => {
            if (error.response && error.response.status === 404) {
                setShowBillingModal(true);
            } else {
                alert("Eroare la obținerea adresei de facturare. Vă rugăm să încercați din nou");
            }
            setLoading(false);
        });
    }

    function setAsMainCard(id: string) {
        setLoading(true);
        setMainCard(id)
            .then(() => {props.setUpdate && props.setUpdate(true)})
            .catch(() => {alert("Eroare la setarea cardului principal")})
            .finally(() => setLoading(false));
    }

    return (
        <>
            {loading && <div className="loading-overlay">Vă rugam așteptați...</div>}
            <Typography variant="h5">Cardurile mele</Typography>
            { props.cards ?
                props.cards.userCards.map((card, index) => {
                    return (
                        <div key={index} className="parent" style={{border: "solid, 2px", borderRadius: "30px", justifyContent:"space-evenly"}}>
                            <CardDetails card={card} includeMain={true}/>
                            <div className="column-50-no-equal">
                                {!card.main && card.status === 'VERIFIED'?
                                        <Button className="button-just-text3" onClick={() => {setAsMainCard(card.id)}}>
                                            <Typography>Card Principal</Typography>
                                        </Button>
                                    :
                                        <></>
                                }
                                <Button className="button-just-text3" onClick={() => {deleteCard(card.id)}}>
                                    <Typography>Ștergere card</Typography>
                                </Button>
                            </div>
                        </div>
                    )
                })
                :
                <></>
            }
            <div style={{textAlign:"center"}}>
                <Button className="button-just-text3" style={{margin:"10px", alignItems:"center"}} onClick={() => {addNewCard()}}>
                    <Typography>Adaugă card nou</Typography>
                </Button>
            </div>
            <></>

            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={showBillingModal} onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Înainte de a adăuga un card nou, trebuie să setezi adresa de facturare</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <BillingAddressHandler userBillingAddress={props.billingAddress} update={props.update} setUpdate={props.setUpdate} setShowModal={setShowBillingModal}/>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UserCardsShower;
