import React, {useEffect} from 'react';
import '../App.css';

function Privacy() {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
  return (
      <>

          <div className="App">
              <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
              <div className="App-body fauna-one-regular">
                  <div className="fauna-one-regular terms">
                      <h2>Politica de Confidențialitate</h2>

                      <h4>1. Introducere</h4>
                      <div className="span-minor-text-without-padding">Această Politică de Confidențialitate descrie
                          modul în care Raise a Reader colectează,
                          utilizează și protejează informațiile dvs. personale atunci când utilizați platforma și
                          serviciile noastre. Prin utilizarea platformei noastre, sunteți de acord cu colectarea și
                          utilizarea informațiilor dvs. personale conform acestei politici.
                      </div>

                      <h4>2. Informații Colectate</h4>
                      <div className="span-minor-text-without-padding">Colectăm următoarele tipuri de informații:</div>
                      <ul className="span-minor-text-without-padding">
                          <li><strong>Informații de identificare personală:</strong> Nume, adresă de e-mail, număr de
                              telefon, adresa de domiciliu.
                          </li>
                          <li><strong>Informații despre utilizare:</strong> Detalii despre modul în care utilizați
                              platforma noastră, inclusiv paginile accesate și interacțiunile efectuate.
                          </li>
                          <li><strong>Informații de la terți:</strong> Informații primite de la terți, cum ar fi datele
                              de autentificare de pe platformele de social media.
                          </li>
                      </ul>

                      <h4>3. Utilizarea Informațiilor</h4>
                      <div className="span-minor-text-without-padding">Utilizăm informațiile colectate pentru:</div>
                      <ul className="span-minor-text-without-padding">
                          <li>A furniza și menține serviciile noastre.</li>
                          <li>A vă notifica despre modificările aduse serviciilor noastre.</li>
                          <li>A vă permite să participați la funcțiile interactive ale serviciilor noastre, atunci când
                              alegeți să faceți acest lucru.
                          </li>
                          <li>A oferi suport clienților.</li>
                          <li>A colecta analize sau informații valoroase pentru a îmbunătăți serviciile noastre.</li>
                          <li>A monitoriza utilizarea serviciilor noastre.</li>
                          <li>A detecta, preveni și aborda problemele tehnice.</li>
                      </ul>

                      <h4>4. Divulgarea Informațiilor</h4>
                      <div className="span-minor-text-without-padding">Nu vindem, nu comercializăm și nu închiriem
                          informațiile dvs. personale altor părți fără
                          consimțământul dvs., cu excepția cazului în care este necesar pentru a:
                      </div>
                      <ul className="span-minor-text-without-padding">
                          <li>Respecta legile sau reglementările aplicabile.</li>
                          <li>Proteja drepturile, proprietatea sau siguranța noastră, a utilizatorilor noștri sau a
                              altor persoane.
                          </li>
                          <li>Furniza informații de utilizare agregate și anonimizate partenerilor noștri de afaceri
                              pentru analize statistice.
                          </li>
                      </ul>

                      <h4>5. Securitatea Informațiilor</h4>
                      <div className="span-minor-text-without-padding">Luăm măsuri rezonabile pentru a proteja
                          informațiile dvs. personale împotriva accesului
                          neautorizat, utilizării sau divulgării. Cu toate acestea, nicio metodă de transmitere prin
                          internet sau metodă de stocare electronică nu este complet sigură și nu putem garanta
                          securitatea absolută a informațiilor dvs.
                      </div>

                      <h4>6. Drepturile Dvs.</h4>
                      <div className="span-minor-text-without-padding">Aveți următoarele drepturi în legătură cu
                          informațiile dvs. personale:
                      </div>
                      <ul className="span-minor-text-without-padding">
                          <li><strong>Dreptul de acces:</strong> Puteți solicita accesul la informațiile dvs. personale
                              pe care le deținem.
                          </li>
                          <li><strong>Dreptul la rectificare:</strong> Puteți solicita corectarea informațiilor
                              personale inexacte sau incomplete.
                          </li>
                          <li><strong>Dreptul la ștergere:</strong> Puteți solicita ștergerea informațiilor dvs.
                              personale în anumite circumstanțe.
                          </li>
                          <li><strong>Dreptul de a restricționa prelucrarea:</strong> Puteți solicita restricționarea
                              prelucrării informațiilor dvs. personale în anumite condiții.
                          </li>
                          <li><strong>Dreptul la portabilitatea datelor:</strong> Puteți solicita transferul
                              informațiilor dvs. personale către o altă organizație sau către dvs., în anumite condiții.
                          </li>
                      </ul>

                      <h4>7. Cookie-uri și Tehnologii Similare</h4>
                      <div className="span-minor-text-without-padding">Folosim cookie-uri și tehnologii similare pentru
                          a colecta informații despre activitatea dvs.
                          pe platforma noastră. Puteți seta browserul dvs. să refuze toate cookie-urile sau să vă anunțe
                          când un cookie este trimis. Cu toate acestea, dacă nu acceptați cookie-urile, este posibil să
                          nu puteți utiliza unele părți ale platformei noastre.
                      </div>

                      <h4>8. Modificări la Politica de Confidențialitate</h4>
                      <div className="span-minor-text-without-padding">Ne rezervăm dreptul de a actualiza Politica
                          noastră de Confidențialitate în orice moment. Vă
                          vom notifica despre orice modificări prin postarea noii Politici de Confidențialitate pe
                          această pagină. Vă recomandăm să revizuiți periodic această Politică de Confidențialitate
                          pentru a fi informat despre orice modificări.
                      </div>

                      <h4>9. Contactați-ne</h4>
                      <div className="span-minor-text-without-padding">Dacă aveți întrebări sau preocupări cu privire la
                          această Politică de Confidențialitate, vă
                          rugăm să ne contactați la <a href="mailto:contact@raiseareader.ro">contact@raiseareader.ro</a>.
                      </div>

                      <h4><strong>Acceptarea Politicii de Confidențialitate</strong></h4>
                      <div className="span-minor-text-without-padding">Prin utilizarea platformei și serviciilor
                          noastre, recunoașteți că ați citit, înțeles și
                          sunteți de acord să fiți legați de această Politică de Confidențialitate.
                      </div>
                  </div>
              </div>
          </div>

      </>
  )
      ;
}

export default Privacy;
