import {http} from "../core/services/http";
import {AddCardResponse, ConfigDto, PaymentMethod, PayRequest, PayResponse} from "./model/Payments";


export const getPaymentMethods = (forPayments?: boolean) =>
    http.get<PaymentMethod[]>(`/payments/payment-methods`, { params: { usableForPayment: forPayments } })

export const deleteCardById = (id: string) =>
    http.delete(`/payments/cards/${id}`)

export const addNewCardRequest = (request: ConfigDto) =>
    http.post<AddCardResponse>(`/payments/add-card`, request)

export const setMainCard = (id: string) =>
    http.patch(`/payments/cards/${id}/main`)

export const pay = (request: PayRequest) =>
    http.post<PayResponse>(`/payments/pay`, request)