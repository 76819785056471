import {http} from "../core/services/http";

import {Exchange, InstitutionGroup} from "./model/GroupExchangeResponse";
import {ExchangeStatus} from "./model/ExchangeStatus";

export const getInstitutionGroups = () =>
    http.get<InstitutionGroup[]>('/books/exchange/institution-groups')

export const getExchanges = (groupId:string) =>
    http.get<Exchange>(`/books/exchange/institution-groups/${groupId}/exchanges`)

export const setGroupToStatus = (scheduleId:string, groupId: string, status: ExchangeStatus ) =>
    http.post<Exchange>(`/books/exchange/schedule-entry/${scheduleId}/group/${groupId}/${status}`)

export const setBookToStatus = (scheduleId:string, bookId: string, status: ExchangeStatus ) =>
    http.post<Exchange>(`/books/exchange/schedule-entry/${scheduleId}/book/${bookId}/${status}`)

