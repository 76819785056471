import '../App.css';
import React, {useRef, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Box, Grid, Typography} from "@mui/material";
import {Book} from "../services/model/Books";
import {IBookInstanceThumbnail, ReadingPlanGroup} from "../services/model/IUserReadingPlan";
import {getAvailableAdditionalBooks, reserveAdditionalBook} from "../services/readingPlan";
import {getBookBySlug} from "../services/booksService";
import BookDetailsElement from "./BookDetailsElement";

type Props = {
    book?: IBookInstanceThumbnail,
    group?: ReadingPlanGroup,
    backupPlanColor? : string
    isReserve : boolean,
    isAdditionalBook : boolean,
    kidProfileId? : string
}

const BookInPlan = (props : Props) => {

    const [show, setShow] = useState(false);
    const [selectedBook, setSelectedBook] = useState<Book>();
    const [selectedBookInstance, setselectedBookInstance] = useState<IBookInstanceThumbnail>();
    const [bookDetails, setBookDetails] = useState(false);
    const [additionalBooks, setAdditionalBooks] = useState<IBookInstanceThumbnail[]>([]);
    const modalContentRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        setShow(false);
        window.location.reload();
    }
    const handleShow = () => {
        if (props.group?.userAssignedReadingId) {
            getAvailableAdditionalBooks(props.group?.userAssignedReadingId).then(response => setAdditionalBooks(response.data));
        }

        setShow(true);
    }

    function selectBook(bookInstance: IBookInstanceThumbnail) {
        getBookBySlug(bookInstance.slug).then(book => {
            setBookDetails(true);
            setSelectedBook(book.data);
            setselectedBookInstance(bookInstance);
            if (modalContentRef.current) {
                modalContentRef.current.scrollTo(0, 0);
            }
        })
    }

    function reserveBook(book: IBookInstanceThumbnail | undefined, group: ReadingPlanGroup | undefined) {
        if (group === undefined || book === undefined) {
            setBookDetails(false);
            handleClose();
            return;
        }

        reserveAdditionalBook({kidProfileId: props.kidProfileId, booksInstanceId: book.instanceId, assignedPackageId: group.userAssignedReadingId}).then(response => {
            setBookDetails(false);
            handleClose();
        });
    }

    function clearSelectBook() {
        setSelectedBook(undefined);
        setselectedBookInstance(undefined);
        setBookDetails(false);
    }

    return (
        <>
            {!props.isReserve && props.book ?
                <Grid item xs={6} sm={6} md={2.4} lg={2.4} key={props.book.id}>
                    <Box>
                        <div>
                            {props.isAdditionalBook ?
                                <Button className="button-just-text" onClick={handleShow}>
                                    {props.book.photoUrl ? (
                                        <Box key={props.book.photoUrl} sx={{
                                            marginBottom: 2,
                                            filter: "drop-shadow(" + props.backupPlanColor + "60 20px 30px 10px)"
                                        }}>
                                            <div style={{position: "relative", display: "inline-block"}}>

                                                <span className="plan-dot" style={{backgroundColor: props.backupPlanColor}}/>

                                                <img className='image_wrapper bookInPlan' src={props.book.photoUrl}
                                                     alt={props.book.name} style={{
                                                    position: 'relative'
                                                }}/>
                                            </div>
                                        </Box>

                                    ) : (
                                        <Typography variant="body2">No photos available</Typography>
                                    )}
                                </Button>
                                :
                                <Link to={`/books/details/` + props.book.slug}>
                                    {props.book.photoUrl ? (
                                        <Box key={props.book.photoUrl} sx={{
                                            marginBottom: 2,
                                            filter: "drop-shadow(" + props.group?.color + "60 20px 30px 10px)"
                                        }}>
                                            <div style={{ position: "relative", display: "inline-block"}}>

                                                <span className="plan-dot" style={{backgroundColor: props.group?.color}}/>

                                                <img className='image_wrapper bookInPlan' src={props.book.photoUrl}
                                                     alt={props.book.name}/>
                                            </div>
                                        </Box>

                                    ) : (
                                        <Typography variant="body2">No photos available</Typography>
                                    )}
                                </Link>
                            }
                            <Typography variant="h6" gutterBottom>{props.isAdditionalBook ? "Cartea suplimentară: " + props.book.name : props.book.name}</Typography>
                            <div className="overlay overlay_2">
                                <h5><strong>Vezi detalii</strong></h5>
                            </div>
                        </div>
                    </Box>
                </Grid>
                :
                <Grid item xs={6} sm={6} md={2.4} lg={2.4}>
                    <Box>
                        <div>
                            <Button className="button-just-text3" onClick={handleShow}>
                                <Typography variant="body2">Rezervă acum</Typography>
                            </Button>
                            <Typography variant="h6" gutterBottom>Carte suplimentară</Typography>
                            <div className="overlay overlay_2">
                                <h5><strong>Vezi detalii</strong></h5>
                            </div>
                        </div>
                    </Box>
                </Grid>
            }

            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cărți suplimentare disponibile</Modal.Title>
                    {bookDetails && selectedBook ?
                        <>
                            <div className="parent" style={{flexWrap:"nowrap"}} ref={modalContentRef}>
                                <div className="column-50-no-equal">
                                    <Button className="button-contact button-just-text3" onClick={clearSelectBook}>Înapoi</Button>
                                </div>
                                <div className="column-50-no-equal" >
                                    <Button className="button-contact button-just-text3" onClick={() => {reserveBook(selectedBookInstance, props.group)}}
                                            disabled={selectedBookInstance?.reserved}>{selectedBookInstance?.reserved ? "Cartea a fost deja rezervată" : "Rezervă"}</Button>
                                </div>
                            </div>

                        </>
                        :
                        <></>
                    }
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    { bookDetails && selectedBook ? <><BookDetailsElement book={selectedBook}></BookDetailsElement></>
                        :
                        (<Grid container spacing={2}>
                            {additionalBooks.map(book => {
                                return (
                                    <Grid item xs={5.5} sm={5.5} md={2.4} lg={2.4} key={book.id}>
                                        <div>
                                            <Button className="button-just-text" onClick={() => {selectBook(book)}}>
                                                {book.photoUrl ? (
                                                    <Box key={book.photoUrl} sx={{
                                                        marginBottom: 2,
                                                        filter: "drop-shadow(" + props.backupPlanColor + "60 20px 30px 10px)"
                                                    }}>
                                                        <div style={{position: "relative", display: "inline-block"}}>

                                                            <span className="plan-dot"
                                                                  style={{backgroundColor: props.backupPlanColor}}/>

                                                            <img className='image_wrapper bookInPlan' src={book.photoUrl}
                                                                 alt={book.name} style={{
                                                                filter: "grayscale(" + (book.reserved ? "1" : "0") + ")"
                                                            }}/>
                                                        </div>
                                                    </Box>

                                                ) : (
                                                    <Typography variant="body2">No photos available</Typography>
                                                )}
                                            </Button>
                                            <Typography variant="h6" gutterBottom>{book.name}</Typography>
                                            <div className="overlay overlay_2">
                                                <h5><strong>Vezi detalii</strong></h5>
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            )}
                        </Grid>)
                    }

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default BookInPlan;