import {http} from "../core/services/http";
import {Pagination} from "./model/Pagination";
import {Book, IBookThumbnail} from "./model/Books";

export const getPublicBooks = (page: number, size : number) =>
    http.get<Pagination<IBookThumbnail>>('/books', {params: {page, size}})

export const getFilteredBooks = (filter:string, slug:string, page: number, size : number) =>
    http.get<Pagination<IBookThumbnail>>(`/books/filter/${filter}/${slug}`, {params: {page, size}})

export const getBookBySlug = (slug:string) =>
    http.get<Book>(`/books/details/${slug}`)