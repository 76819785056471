import * as React from 'react';
import {Button} from 'react-bootstrap'
import logoS from "../../assets/logo_s.svg";
import logo from "../../assets/logo_l.svg";
import BasicMenu from "./BasicMenu";
import {Link, useNavigate} from "react-router-dom";
import LoginModal from "./LoginModal";
import {applyHere, getUserDetails} from "../../services/userService";
import {useCallback, useEffect} from "react";
import {isAuthenticated} from "../../services/authStorageService";

export default function AppHeader() {
    const navigate = useNavigate();

    function navigateToRoot() {
        navigate("/");
        window.scrollTo(0, 0);
    }

    function navigateToHome() {
        navigate("/");
    }
    function navigateToBooks() {
        navigate("/books");
    }
    function navigateToBookClubs() {
        navigate("/bookClubs");
    }
    function navigateToTeacherGroups() {
        navigate("/teacher-groups");
    }
    function navigateToContactUs() {
        applyHere();
        navigate("/contactUs");
    }


    function navigateToMyPlan() {
        navigate("/my-plan");
    }

    const [hasGroup, setHasGroup] = React.useState(false);
    const [isTeacher, setIsTeacher] = React.useState(false);

    useEffect(()=> {
        if (isAuthenticated()) {
            getUserProfile();
        }
    }, [isAuthenticated]);

    const getUserProfile = useCallback(async () => {
        const axiosResponse = await getUserDetails();
        const kids = axiosResponse.data.kids;
        if (kids != null && kids.length > 0) {
            if (kids.some(kid => kid.groupDetails && kid.groupDetails.id)) {
                setHasGroup(true);
            }
        }
        const role = axiosResponse.data.userType;
        if (role === 'TEACHER') {
            setIsTeacher(true);
        }

    }, []);


    return (
        <header className="App-header">
            <div className="action-menu-small parent">
                <div className="column-50 align-start" style={{paddingLeft: "20px"}}>
                    <img src={logoS} onClick={navigateToRoot}></img>
                </div>
                <div className="column-50 align-end">
                    <BasicMenu></BasicMenu>
                </div>
            </div>
            <div className="app-header-div">
                <div className="parent action-menu-big">
                    <div className="column-33" style={{alignItems: "center"}}>
                        <div style={{display: "inline-flex", justifyContent: "center", flexDirection: "row"}}>
                                <Button className="button-just-text"  onClick={navigateToHome}>
                                    Acasă
                                </Button>
                                { hasGroup ?
                                    <Button className="button-just-text" onClick={navigateToMyPlan}>
                                        Planul meu
                                    </Button>
                                    : isTeacher ?
                                        <Button className="button-just-text" onClick={navigateToTeacherGroups}>
                                            Cluburile grădiniței mele
                                        </Button> :
                                        <Button className="button-just-text" onClick={navigateToBookClubs}>
                                            Cluburi de lectură
                                        </Button>
                                }

                            <Button className="button-just-text" onClick={navigateToBooks}>
                                Cărți
                            </Button>

                        </div>
                    </div>
                    <div className="column-33">
                        <img src={logo} className="logo" onClick={navigateToRoot}></img>
                    </div>
                    <div className="column-33" style={{padding: 0, "alignItems": "center"}}>
                        <div style={{"display": "flex", "alignItems": "self-end"}}>
                            {/*<Button className="button-just-text button-selected"*/}
                            {/*        onClick={event => window.location.href = 'https://forms.gle/tuMPTzVEQNxiMmwG9'}>*/}
                            {/*    Intră în cont*/}
                            {/*</Button>*/}
                            <LoginModal />

                            <Button className="button button-contact button-just-text" onClick={navigateToContactUs}>
                                Aplică aici
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
