import Form from "react-bootstrap/Form";
import {Button, Col, Row} from "react-bootstrap";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {KidProfile, Kindergarten} from "../../services/model/UserProfile";
import {addKidProfile, removeKidProfile, updateKidProfile} from "../../services/userService";

type Props = {
    kidDetails: KidProfile,
    kindergartens: Kindergarten[],
    addKid: boolean,
    update: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

function KidProfileComponent(props: Props) {

    const navigate = useNavigate();
    const [kidProfile, setKidProfile] = useState<KidProfile>(props.kidDetails);

    useEffect(() => {
        setKidProfile(props.kidDetails);
    }, [props.kidDetails])

    const [validated, setValidated] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setKidProfile({
            ...kidProfile,
            [e.target.name]: e.target.value
        });
        if (e.target.name === "name") {
            props.kidDetails.name = e.target.value;
        }
    };

    const handleInstitutionChange = (e: ChangeEvent<HTMLInputElement>) => {
        var kidProfileCopy = {...kidProfile};
        if (kidProfileCopy.groupDetails == undefined) {
            kidProfileCopy.groupDetails = {};
            props.kidDetails.groupDetails = {};
        }
        kidProfileCopy.groupDetails.institutionId = e.target.value;
        props.kidDetails.groupDetails!.institutionId = e.target.value;

        setKidProfile(kidProfileCopy);
    };

    const handleSubmit = (event: FormEvent) => {
        const form = event.currentTarget;
        // @ts-ignore
        if (form.checkValidity() === false) {
        } else {
            console.log(kidProfile);
            if (props.addKid) {
                addKidProfile(kidProfile).then(value => props.update(true));
            } else {
                updateKidProfile(kidProfile.id!, kidProfile).then(value => props.update(true));
            }
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    }

    function removeKid() {
        if (kidProfile.id) {
            removeKidProfile(kidProfile.id).then(value => props.update(true));
        }
    }

    return (
        <>
            <div className="info-topic2">
                <div style={{padding: "20px"}}>
                    <Form id={"kid-profile-" + kidProfile.id} noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Row className="g-2">
                                <Col md>
                                    <Form.Label>
                                        <span className="span-minor-text">Nume *</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="input"
                                        name="name"
                                        placeholder="Nume"
                                        defaultValue={kidProfile?.name}
                                        onChange={handleChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Te rugăm să introduci numele copilului.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                <span className="span-minor-text">Data nașterii *</span>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="birthdate"
                                placeholder="Data nașterii"
                                defaultValue={kidProfile?.birthdate}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci ziua de naștere.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                <span className="span-minor-text">Grădinița</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="groupDetails.institutionName"
                                placeholder="Grădinița"
                                defaultValue={kidProfile?.groupDetails?.institutionName}
                                onChange={handleChange}
                                readOnly={true}
                                disabled={true}
                            />
                            {/*<Form.Control*/}
                            {/*    as="select"*/}
                            {/*    name="groupDetails.institutionId"*/}
                            {/*    value={kidProfile?.groupDetails?.institutionId || ""}*/}
                            {/*    onChange={handleInstitutionChange}*/}
                            {/*>*/}
                            {/*    <option value="" selected={!kidProfile.groupDetails?.id}>Selectează grădinița</option>*/}
                            {/*    {props.kindergartens.map((kindergarten) => (*/}
                            {/*        <option key={kindergarten.id} value={kindergarten.id}*/}
                            {/*                selected={kindergarten.id === kidProfile.groupDetails?.id}>*/}
                            {/*            {kindergarten.name} - {kindergarten.city}*/}
                            {/*        </option>*/}
                            {/*    ))}*/}
                            {/*</Form.Control>*/}
                        </Form.Group>

                        <Form.Group style={{textAlign: 'center'}}>
                            <Row className="g-2">
                                {props.addKid ?
                                    <Col md>
                                        <Button type="submit" className="button button-contact">
                                            Salvează
                                        </Button>
                                    </Col>
                                :
                                    <>
                                        <Col md>
                                            <Button onClick={removeKid} className="button button-contact">
                                                Șterge
                                            </Button>
                                        </Col>
                                        <Col md>
                                        <Button type="submit" className="button button-contact">
                                        Salvează
                                        </Button>
                                        </Col>
                                    </>
                                }

                            </Row>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default KidProfileComponent;
