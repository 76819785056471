import * as React from 'react';
import {Link} from "react-router-dom";
import {isAuthenticated} from "../../services/authStorageService";
import {getUserDetails} from "../../services/userService";
import {useCallback, useEffect} from "react";

export default function RegisterSection() {

    const [hasGroup, setHasGroup] = React.useState(false);
    const [isTeacher, setIsTeacher] = React.useState(false);

    useEffect(()=> {
        if (isAuthenticated()) {
            getUserProfile();
        }
    }, [isAuthenticated]);

    const getUserProfile = useCallback(async () => {
        const axiosResponse = await getUserDetails();
        const kids = axiosResponse.data.kids;
        if (kids != null && kids.length > 0) {
            if (kids.some(kid => kid.groupDetails && kid.groupDetails.id)) {
                setHasGroup(true);
            }
        }
        const role = axiosResponse.data.userType;
        if (role === 'TEACHER') {
            setIsTeacher(true);
        }

    }, []);

    return (
        <>
            <div className="gradient-wave-bg flip" style={{transform: 'rotate(180deg)'}}></div>
            <div id="wave2" className="parent align-left top-section div-with-background" style={{marginTop: '-5px'}}>
                <div className="small-container " style={{textAlign: 'center'}}>
                    <h2>Avem sute de cărți și vrem să te ajutăm și pe tine <br/>să îi faci pe copiii tăi să <span
                        className="low-highlight-purple">IUBEASCĂ</span> cititul.</h2><br/>
                    {
                         !isAuthenticated() ?
                                 <h5>
                                     Înregistrează-te sau autentifică-te pentru a debloca instantaneu mai multe
                                     cărți!<br/>
                                     <Link to="/login">
                                         <div className="button button-contact"
                                              style={{marginLeft: '10px', marginTop: '10px', width: "fit-content"}}>
                                             <span>Intră în cont</span>
                                         </div>
                                     </Link>
                                 </h5>
                        :
                        !hasGroup && !isTeacher ?
                                 <h5>
                                     Fă upgrade pentru a debloca instantaneu mai multe cărți plus alte beneficii numai
                                     pentru membri.<br/>
                                     Alege-ți abonamentul care ți se potrivește.<br/>
                                     <Link to="/subscription">
                                         <div className="button button-contact"
                                              style={{marginTop: '10px', width: "fit-content"}}>
                                        <span>Abonează-te acum</span>
                                    </div>
                                </Link>
                            </h5>
                                 :
                                 <></>
                    }
                </div>
            </div>
            <div className="gradient-wave-bg" style={{marginTop: '-20px', transform: 'rotate(360deg)'}}></div>
            {
                !(isAuthenticated() || hasGroup || isTeacher) ?
                    <div>

                        <div className="gradient-wave-bg-orange flip" style={{marginTop: '-150px', transform: 'rotate(180deg)'}}></div>
                        <div id="wave2" className="parent align-left top-section orange-background"
                             style={{marginTop: '-5px'}}>
                            <div className="small-container " style={{textAlign: 'center'}}>
                                <h5>
                                    <h2>Vrei mai mult? Alege un club Raise A Reader!</h2>
                                    Bucură-te de livrare convenabilă în cadrul grădiniței, planuri
                                    personalizate cu cărți atent alese și materiale care susțin cititul cărților.<br/><br/>
                                    <Link to="/bookClubs">
                                        <div className="button button-contact button-contact-orange"
                                             style={{marginLeft: '10px', width: "fit-content"}}>
                                            <span>Află cum funcționează<br/> cluburile de lectură</span>
                                        </div>
                                    </Link>
                                </h5>
                            </div>
                        </div>
                        <div className="gradient-wave-bg-orange"
                             style={{marginTop: '-10px', transform: 'rotate(360deg)'}}></div>
                    </div>

                    :
                    <></>
            }
        </>

    );
}
