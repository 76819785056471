import '../App.css';
import React, {useCallback, useEffect, useState} from "react";
import {getInstitutionGroups} from "../services/teacherService";
import {InstitutionGroup} from "../services/model/GroupExchangeResponse";
import {Grid} from "@mui/material";
import {Link} from "react-router-dom";

function TeacherGroups() {
    const [institutionGroups, setInstitutionGroups] = useState<InstitutionGroup[]>([]);

    const getData = useCallback(async () => {
        try {
            const response = await getInstitutionGroups();
            setInstitutionGroups(response.data);
        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        getData();
    },[])

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <>
            <div className="App">
                <div id="wave" className="parent align-left top-section div-with-background">
                    <div className="small-container ">
                        <br/>
                        <h2>Cluburile de lectură create în grădinița dumneavoastră sunt gândite special pentru a ajuta părinții să crească mai
                            ușor un copil cititor!</h2><br/>

                        <h5>Aici puteți să vedeți cluburile create în interiorul grădiniței dumneavoastră.</h5>
                        <h5>Selectați un club pentru a vedea planurile de lectură pentru membrii săi.</h5>
                    </div>
                </div>
                <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
                <div className="App-body fauna-one-regular">
                    <Grid container spacing={2}>

                        {institutionGroups.map((group, index) => {
                                return (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Link to={`/group-details/` + group.id}>
                                                <div className="button button-contact">
                                                    <span>{group.name}</span>
                                                </div>
                                            </Link>
                                        </Grid>
                                )
                            }
                        )}
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default TeacherGroups;
