import React, {useCallback, useEffect, useState} from 'react';
import '../App.css';
import {Box, Card, CardContent, CardMedia, Grid, Typography} from '@mui/material';
import {Book, SimpleQuestion} from "../services/model/Books";
import {getBookBySlug} from "../services/booksService";
import {Link, useParams} from "react-router-dom";
import {ReadMore} from "../core/components/ReadMore";
import YouTube from "react-youtube";
import BookDetailsElement from "./BookDetailsElement";


function BookDetails() {
    const {slug} = useParams();

    const [book, setBook] = useState<Book>();

    const getData = useCallback(async () => {
        try {
            const bookResponse = await getBookBySlug(slug!);
            setBook(bookResponse.data);
        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        getData();

    }, [])



    return (
      <>

          <div className="App">

              <div className="App-body fauna-one-regular">
                  <div className="fauna-one-regular terms" style={{width: "100%"}}>
                      {book == undefined ?
                          <div></div>
                          :
                          <BookDetailsElement  book={book}/>
                      }
                  </div>
              </div>
          </div>

      </>
  );
}

export default BookDetails;