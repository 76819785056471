import Form from "react-bootstrap/Form";
import {Button, Col, Modal, Row} from "react-bootstrap";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {changePassword, updateUserDetails} from "../../services/userService";
import {setRefreshToken, setToken} from "../../services/authStorageService";
import {useNavigate} from "react-router-dom";
import {UserProfile} from "../../services/model/UserProfile";
import {ChangePasswordRequest} from "../../services/model/ChangePasswordRequest";

type Props = {
    userDetails: UserProfile
}

function MyProfile(props : Props)  {

    const navigate = useNavigate();
    const [validatedChangedPassword, setValidatedChangedPassword] = useState(false);
    const [userProfile, setUserProfile] = useState<UserProfile>(props.userDetails);
    const [show, setShow] = useState(false);
    const [changePasswordRequest, setChangePasswordRequest] = useState<ChangePasswordRequest>({
        email: props.userDetails.email,

    });

    useEffect(() => {
        setUserProfile(props.userDetails);
    }, [props.userDetails])

    const handleShow = () => setShow(true);
    const [validated, setValidated] = useState(false);

    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        setUserProfile({
            ...userProfile,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (event: FormEvent) => {
        const form = event.currentTarget;
        // @ts-ignore
        if (form.checkValidity() === false) {
        } else {
            updateUserDetails(userProfile).then();
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };

    const handleClose = () => {
        setShow(false);
    }

    const handleChangePassword = (e : ChangeEvent<HTMLInputElement>) => {
        setChangePasswordRequest({
            ...changePasswordRequest,
            [e.target.name]: e.target.value
        });
    };


    function handleSubmitChangePassword(event: FormEvent) {
        const form = event.currentTarget;
        // @ts-ignore
        if (form.checkValidity() === false) {
        } else {
            changePassword(changePasswordRequest).then((response) => {
                setToken(response.data.access_token)
                setRefreshToken(response.data.refresh_token)
                handleClose();
            }).catch(() => {
                alert("Parola nu a putut fi schimbată. Te rugăm să reîncerci.");
            });
        }
        event.preventDefault();
        event.stopPropagation();
        setValidatedChangedPassword(true);
    }


    return (
        <>
            <div className="info-topic2" >
                <div style={{padding:"20px"}}>
                    <Form id="contact-us-form" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Row className="g-2">
                                <Col md>
                                    <Form.Label>
                                        <span className="span-minor-text">Prenume *</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="input"
                                        name="firstName"
                                        placeholder="Nume"
                                        defaultValue={userProfile?.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Te rugăm să introduci prenumele.
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md>
                                    <Form.Label>
                                        <span className="span-minor-text">Nume *</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="input"
                                        name="lastName"
                                        placeholder="Nume"
                                        defaultValue={userProfile?.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Te rugăm să introduci numele.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                <span className="span-minor-text">Email *</span>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                disabled
                                placeholder="Email"
                                defaultValue={userProfile?.email}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci un email valid.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                <span className="span-minor-text">Telefon *</span>
                            </Form.Label>
                            <Form.Control
                                type="input"
                                name="phone"
                                placeholder="Telefon"
                                defaultValue={userProfile?.phone}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci un număr de telefon valid.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group style={{textAlign: 'center'}}>
                            <Row className="g-2">
                                <Col md>
                                    <Button className="button button-contact" onClick={handleShow}>
                                        Schimbă parola
                                    </Button>
                                </Col>
                                <Col md>
                                    <Button type="submit" className="button button-contact">
                                        Salvează
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </div>
            </div>

            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Schimbă parola</Modal.Title>
                </Modal.Header>
                <Form id="change-password-form" noValidate validated={validatedChangedPassword} onSubmit={handleSubmitChangePassword}>

                    <Modal.Body>
                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                <span className="span-minor-text">Parolă veche *</span>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                name="oldPassword"
                                placeholder="*******"
                                onChange={handleChangePassword}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci o parolă validă.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                            <Form.Label>
                                <span className="span-minor-text">Parolă nouă *</span>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                name="newPassword"
                                placeholder="*******"
                                onChange={handleChangePassword}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Te rugăm să introduci o parolă validă.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            OK
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>

        </>
    );
}
export default MyProfile;
