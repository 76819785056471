import React from "react";
import {Typography} from "@mui/material";
import {FcCheckmark} from "react-icons/fc";
import {PiExclamationMarkBold} from "react-icons/pi";
import {PaymentMethod} from "../../services/model/Payments";

type CardDetailsProps = {
    card: PaymentMethod["userCards"][0];
    includeMain: boolean;
};

const CardDetails: React.FC<CardDetailsProps> = ({card, includeMain}) => {
    return (
        <>
            {includeMain ?
                <div className="column-50-no-equal" style={{alignItems: "flex-start"}}>
                    <Typography>{card.status === 'PENDING' ? <PiExclamationMarkBold size="30"/> :
                        <FcCheckmark size="30"/>}</Typography>
                    <Typography>{card.main ? "Principal" : <></>}</Typography>
                </div>
            :
            <></>
            }

            <div className="column-50-no-equal" style={{alignItems: "flex-start"}}>
                <Typography>{card.cardHolderName} </Typography>
                <Typography>{card.panMasked} </Typography>
                <Typography>{new Date(card.expirationTime).toLocaleDateString(window.navigator.language, {
                    month: '2-digit',
                    year: '2-digit'
                }).replace(',', '/')} </Typography>
            </div>
        </>
    );
};

export default CardDetails;