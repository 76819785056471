import '../App.css';
import {Box, Grid, Typography} from '@mui/material';
import {Link} from "react-router-dom";
import {AdditionalBookExchange, ExchangeSchedules} from "../services/model/GroupExchangeResponse";
import ExchangeDateElement from "./ExchangeDateElement";
import React, {useEffect} from "react";
import {Button} from "react-bootstrap";
import {setBookToStatus, setGroupToStatus} from "../services/teacherService";

type Props = {
    additionalBook: AdditionalBookExchange,
    exchangeSchedules: ExchangeSchedules,
    additionalGroupColor: string
}

const AdditionalBookExchangeElement = (props : Props) => {

    const [updated, setUpdated] = React.useState(false);
    useEffect(() => {
        setUpdated(false);
    }, [updated])

    function handlePreviousBookStatusNotDelivered() {
        setBookToStatus(props.exchangeSchedules.previousSchedule.id, props.additionalBook.id, 'NOT_DELIVERED_YET').then(value => {
            setBookToStatus(props.exchangeSchedules.currentSchedule.id, props.additionalBook.id, 'NOT_RECEIVED').then(value1 => {
                props.additionalBook.previousUser.status = 'NOT_DELIVERED_YET';
                props.additionalBook.currentUser.status = 'NOT_RECEIVED';
                setUpdated(true)
            });
        });
    }

    function handleBookSuccessExchange() {
        if (props.exchangeSchedules.previousSchedule.id !== undefined && props.exchangeSchedules.previousSchedule.id !== null) {
            setBookToStatus(props.exchangeSchedules.previousSchedule.id, props.additionalBook.id, 'DELIVERED').then(value => {
                if (props.exchangeSchedules.currentSchedule.id !== null) {
                setBookToStatus(props.exchangeSchedules.currentSchedule.id, props.additionalBook.id, 'RECEIVED').then(value1 => {
                    props.additionalBook.previousUser.status = 'DELIVERED';
                    props.additionalBook.currentUser.status = 'RECEIVED';
                    setUpdated(true)}
                )} else {
                    props.additionalBook.previousUser.status = 'DELIVERED';
                    props.additionalBook.currentUser.status = 'RECEIVED';
                    setUpdated(true);
                }

            });
        } else {
            setBookToStatus(props.exchangeSchedules.currentSchedule.id, props.additionalBook.id, 'RECEIVED').then(value => {
                props.additionalBook.currentUser.status = 'RECEIVED';
                setUpdated(true)
            });
        }
    }

    return (
        <Grid item xs={12} sm={12} md={6} lg={6} >
            <Box>
                <div style={{ margin: "5px",  border: 'solid', color: props.additionalGroupColor}}>
                    <Box display="flex" bgcolor={props.additionalBook.color} marginBottom="5px">
                                 <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                                     <div style={{justifyContent:"flex-start"}}>
                                      <span className="span-bold-text" style={{
                                          color: "white",
                                          // padding: "5px",
                                          textAlign: "left"
                                      }}>
                                          {(props.exchangeSchedules.previousSchedule !== null && props.exchangeSchedules.previousSchedule !== undefined) ?
                                              <ExchangeDateElement
                                                  startTime={props.exchangeSchedules.previousSchedule.startTimestamp}
                                                  endTime={props.exchangeSchedules.previousSchedule.endTimestamp}
                                                  user={props.additionalBook.previousUser}/>
                                              :
                                              <></>
                                          }
                                          {(props.exchangeSchedules.currentSchedule !== null && props.exchangeSchedules.currentSchedule !== undefined) ?
                                              <ExchangeDateElement
                                                  startTime={props.exchangeSchedules.currentSchedule.startTimestamp}
                                                  endTime={props.exchangeSchedules.currentSchedule.endTimestamp}
                                                  user={props.additionalBook.currentUser}/>
                                              :
                                              <></>
                                          }
                                          {(props.exchangeSchedules.nextSchedule !== null && props.exchangeSchedules.nextSchedule !== undefined) ?
                                              <ExchangeDateElement
                                                  startTime={props.exchangeSchedules.nextSchedule.startTimestamp}
                                                  endTime={props.exchangeSchedules.nextSchedule.endTimestamp}
                                                  user={props.additionalBook.nextUser}/>
                                              :
                                              <></>
                                          }
                              </span>
                            </div>
                        <div className="parent">
                                <div className="column-50" style={{flexDirection: "row", justifyContent: "center"}}>
                                    {
                                        props.additionalBook.currentUser.status === 'RECEIVED' ?
                                            <></>
                                            :
                                            <>
                                                {(props.additionalBook.previousUser?.id !== null && props.additionalBook.previousUser.id !== undefined && (props.additionalBook.previousUser.status !== 'DELIVERED')) ?
                                                    <Button className="button-contact" style={{margin:"10px"}} onClick={() => { handlePreviousBookStatusNotDelivered()}}>
                                                        <Typography variant="body1">Cărți lipsă</Typography>
                                                    </Button>
                                                    :
                                                    <></>
                                                }
                                                {((props.additionalBook.currentUser?.id !== null && props.additionalBook.currentUser?.id !== undefined) || (props.additionalBook.previousUser?.id !== null && props.additionalBook.previousUser.id !== undefined && (props.additionalBook.previousUser.status !== 'DELIVERED'))) ?
                                                    <Button className="button-contact"  style={{margin:"10px"}} onClick={() => { handleBookSuccessExchange()}}>
                                                        <Typography variant="body1">Schimbat cu succes</Typography>
                                                    </Button>
                                                    :
                                                    <></>
                                                }
                                            </>
                                    }
                                </div>
                                <div className="column-50">
                                <span className="span-bold-text" style={{
                                    color: "white",
                                    padding: "5px",
                                    textAlign: "right"
                                }}>
                                    {props.additionalBook.currentUser.status === 'RECEIVED' ?
                                        <strong>Carte primită</strong> : <></>}
                                    {props.additionalBook.currentUser.status === 'NOT_RECEIVED' ?
                                        <strong>Carte lipsă</strong> : <></>}
                                </span>
                                </div>
                            </div>
                         </div>
                    </Box>
                    <Link to={`/books/details/` + props.additionalBook.book.slug}>
                        {props.additionalBook.book.photoUrl ? (
                            <Box key={props.additionalBook.book.photoUrl} sx={{
                                marginBottom: 2,
                                filter: "drop-shadow(" + props.additionalGroupColor + "60 20px 30px 10px)"
                            }}>
                                <div style={{position: "relative", display: "inline-block"}}>

                                <span className="plan-dot" style={{backgroundColor: props.additionalGroupColor}}/>

                                    <img className='image_wrapper bookInPlan' src={props.additionalBook.book.photoUrl}
                                         alt={props.additionalBook.book.name}/>
                                </div>
                            </Box>

                        ) : (
                            <Typography variant="body2">No photos available</Typography>
                        )}
                    </Link>
                    <Typography variant="h6" gutterBottom>{props.additionalBook.book.name}</Typography>
                    <div className="overlay overlay_2">
                        <h5><strong>Vezi detalii</strong></h5>
                    </div>
                </div>
            </Box>
        </Grid>
    );
}

export default AdditionalBookExchangeElement;