import {http} from "../core/services/http";
import {
    IAdditionalBookRequest,
    IAdditionalBookResponse,
    IBookInstanceThumbnail,
    IUserReadingPlan
} from "./model/IUserReadingPlan";

export const getUserReadingPlan = () =>
    http.get<IUserReadingPlan[]>('/books/plan')

export const getAvailableAdditionalBooks = (packageId:string) =>
    http.get<IBookInstanceThumbnail[]>(`/books/plan/additional-books/package/${packageId}`)

export const reserveAdditionalBook = (request:IAdditionalBookRequest) =>
    http.put<IAdditionalBookResponse>(`/books/plan/additional-books`, request)