import React from "react";

type BoldProps = {
    text?: string,
    boldText?: string
}

const BoldText = (p: BoldProps) => {
    const { text, boldText } = p;
    if (text === undefined || boldText === undefined) {
        return <p>{text}</p>;
    }
    const parts = text.split(boldText);

    return (
        <p>
            {parts.map((part, index) => (
                <React.Fragment key={index}>
                    {part}
                    {index !== parts.length - 1 &&
                        <strong style={{ fontWeight: 'bold' }}>
                            {boldText}
                        </strong>}
                </React.Fragment>
            ))}
        </p>
    );
}

export default BoldText;