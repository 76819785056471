import React from 'react';
import {Input} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import StarIcon from "@mui/icons-material/Star";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

// function handleClick(e: any) {
//     e.preventDefault();
//     var bodyFormData = new FormData();
//     bodyFormData.append('b_2cfc97df37e21fd8e161294e3_e44d9352d7', e.target[0].value);
//     axios
//         ({
//             method: "post",
//             url: "https://gmail.us18.list-manage.com/subscribe/post?u=2cfc97df37e21fd8e161294e3&amp;id=e44d9352d7&amp;f_id=009857e7f0",
//             data: bodyFormData,
//             headers: { "Content-Type": "multipart/form-data",
//                        "Access-Control-Allow-Origin": "*"}
//         })
//         .then(function (response: any) {
//             console.log(response);
//         })
//         .catch(function (error: any) {
//             console.log(error);
//         });
// }

function NewsletterSignupForm() {
  return (
    <div style={{justifyContent:"center", alignItems:"center"}}>
        <form /*onSubmit={handleClick}*/
            action="https://gmail.us18.list-manage.com/subscribe/post?u=2cfc97df37e21fd8e161294e3&amp;id=e44d9352d7&amp;f_id=009857e7f0"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
            <div className="fauna-one-regular header span-minor-text-without-padding white-and-bold-text" style={{textAlign: 'left'}}>
                Alătură-te comunității noastre!
                <List>
                    <ListItem disablePadding>
                        <StarIcon />
                        <ListItemText style={{ paddingLeft: "7px" }} primary="Primești recomandări exclusive de cărți, sfaturi de la experți și oferte speciale." />
                        {/*<ListItemText primary="Rămâneți la curent cu cele mai recente recomandări de cărți, conținut exclusiv și oferte speciale,!" />*/}
                    </ListItem>
                    <ListItem disablePadding>
                        <StarIcon />
                        <ListItemText style={{ paddingLeft: "7px" }} primary="Te simți grozav că alegi să îi dezvoltați copilului tău dragostea pentru lectură!" />
                    </ListItem>
                </List>
            </div>

            <div id="mc_embed_signup_scroll" className="parent" style={{marginTop:'10px'}}>
                <Input name="EMAIL" id="mce-EMAIL" style={{ color: "#FFFFFF"}} placeholder="Adresa ta de email"/>
                <div className="clear" style={{paddingTop:'10px'}}>

                <input type="submit" value="Trimite" name="subscribe" id="mc-embedded-subscribe"
                       className="button button-contact" style={{"width": "fit-content", marginLeft:'3px'}} />
                </div>
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                    <input className="fauna-one-regular" type="text"
                           name="b_2cfc97df37e21fd8e161294e3_e44d9352d7" /*tabindex="-1"*/ defaultValue=""/>
                </div>
            </div>
        </form>
    </div>

      // <div id="mc_embed_shell">
      //     <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
    //         <style type="text/css">
    //             #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px;}
    //             /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
    //             We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    //         </style>
    //         <div id="mc_embed_signup">
    //             <form action="https://gmail.us18.list-manage.com/subscribe/post?u=2cfc97df37e21fd8e161294e3&amp;id=e44d9352d7&amp;f_id=009857e7f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
    //                 <div id="mc_embed_signup_scroll"><h2>Subscribe</h2>
    //                     <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
    //                     <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""><span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span></div>
    //                     <div id="mce-responses" class="clear foot">
    //                         <div class="response" id="mce-error-response" style="display: none;"></div>
    //                         <div class="response" id="mce-success-response" style="display: none;"></div>
    //                     </div>
    //                     <div aria-hidden="true" style="position: absolute; left: -5000px;">
    //                         /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
    //                         <input type="text" name="b_2cfc97df37e21fd8e161294e3_e44d9352d7" tabindex="-1" value="">
    //                     </div>
    //                     <div class="optionalParent">
    //                         <div class="clear foot">
    //                             <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe">
    //                                 <p style="margin: 0px auto;"><a href="http://eepurl.com/iN_LAo" title="Mailchimp - email marketing made easy and fun"><span style="display: inline-block; background-color: transparent; border-radius: 4px;"><img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style="width: 220px; height: 40px; display: flex; padding: 2px 0px; justify-content: center; align-items: center;"></span></a></p>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </form>
    //         </div>
    //         <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>

);
}

export default NewsletterSignupForm;