import React, {useState} from 'react';
import '../../App.css';
import {SubscriptionResponse} from "../../services/model/SubscriptionModels";
import {Carousel} from "react-responsive-carousel";
import {Button, Modal} from "react-bootstrap";
import {FaArrowCircleLeft, FaArrowCircleRight} from "react-icons/fa";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import {getPaymentMethods, pay} from "../../services/paymentsService";
import {PaymentMethod} from "../../services/model/Payments";
import CardDetails from "./CardDetails";
import BillingAddressHandler from "./BillingAddressHandler";
import {getBillingAddress} from "../../services/userService";


type Props = {
    subscription: SubscriptionResponse
    setUpdate?: (value: (((prevState: boolean) => boolean) | boolean)) => void
    expandedKid?: string | false
    expandedSubs?: string | false
    expanded?: string | false
}

function ProfileSubscriptions(props : Props) {

    const [loading, setLoading] = useState(false);
    const [selectedPaymentId, setSelectedPaymentId] = useState<string | undefined>(undefined);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [cards, setCards] = useState<PaymentMethod>();
    const [selectedCardId, setSelectedCardId] = useState<string>();
    const [saveCard, setSaveCard] = useState<boolean>(false);
    const [showBillingModal, setShowBillingModal] = useState(false);

    function getNextPaymentNumber(subscription: SubscriptionResponse) {
        return subscription.payments.findIndex(payment => payment.nextPayment || payment.overdue);
    }


    const handleClose = () => {
        setShowPaymentModal(false);
        setSelectedPaymentId(undefined);

    }


    const handleCloseBilling = () => {
        setShowPaymentModal(false);
        setShowBillingModal(false);
        setSelectedPaymentId(undefined);
    }

    function selectPaymentAndShowModal(paymentId: string) {
        setLoading(true);
        getBillingAddress().then(value => {
            getPaymentMethods(true).then(response => {
                let data = response.data;
                if (data !== undefined && data.length > 0 && data[0] !== undefined ) {
                    setCards(data[0]);
                }
                setSelectedPaymentId(paymentId);
                setShowPaymentModal(true);
            });
        }).catch(error => {
            if (error.response && error.response.status === 404) {
                setShowBillingModal(true);
            } else {
                alert("Eroare la obținerea adresei de facturare. Vă rugăm să încercați din nou");
            }
        }).finally(() => {setLoading(false);});


    }

    function toggleSaveCard() {
        setSaveCard(!saveCard);
    }


    function getUrl() {
        if (!window.location.origin){
            // For IE
            // @ts-ignore
            window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        }

        return  window.location.origin + window.location.pathname;
    }

    function triggerPayment() {
        setShowPaymentModal(false);
        setLoading(true)
        pay({
            cardId:selectedCardId,
            itemId: selectedPaymentId,
            orderType: "SUBSCRIPTION",
            saveCard: saveCard,
            config: {
                redirectUrl: getUrl() + '?openSubscriptionPayment=true&expandedKidPrev=' + props.expandedKid + '&expandedPrev=' + props.expanded + '&expandedSubsPrev=' + props.expandedSubs,
                view: "DESKTOP"
            }
        }).then((value) => {
            if (selectedCardId !== undefined ) {
                alert("Plata a fost efectuată cu succes");
            } else {
                window.location.href = value.data.formUrl
            }
        }).catch(() => alert("Eroare la efectuarea plății"))
            .finally(() => {
                setLoading(false);
                handleClose();
                props.setUpdate && props.setUpdate(true);
            });
    }

    return (
      <>
          {loading && <div className="loading-overlay">Vă rugam așteptați...</div>}

          <div className="info-topic2">
              <div style={{padding: "20px", display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                  <span className="span-bold-text">Durata abonamentului: </span>
                  <span> {props.subscription.startDate} - {props.subscription.endDate}</span>
                    <span className="span-bold-text">Tipul plăți: {props.subscription.recurrenceType === 'MONTHLY' ? "Lunară" : "Anuală"}</span>
                  <div style={{width:"100%"}}>
                  <Carousel showArrows={true} autoPlay={false} animationHandler={"slide"} axis={"horizontal"} showIndicators={false}
                            showThumbs={false} showStatus={false} selectedItem={getNextPaymentNumber(props.subscription)}
                            renderArrowPrev={(clickHandler, hasPrev) => {
                                return (
                                    <div
                                        className={`${
                                            hasPrev ? "absolute" : "hidden"
                                        }`}
                                        style={{opacity: "30", cursor: "pointer", zIndex: 20, alignItems:"center", position:"absolute", left:"0", top:"40%"}}
                                        onClick={clickHandler}
                                    >
                                        <FaArrowCircleLeft />
                                    </div>
                                );
                            }}
                            renderArrowNext={(clickHandler, hasNext) => {
                                return (
                                    <div
                                        className={`${
                                            hasNext ? "absolute" : "hidden"
                                        } `}
                                        style={{opacity: "30", cursor: "pointer", zIndex: 20, alignItems:"center", position:"absolute", right:"0", top:"40%"}}

                                        onClick={clickHandler}
                                    >
                                        <FaArrowCircleRight width="20" height="20"/>
                                    </div>
                                );
                            }}>
                  {props.subscription.payments.map((payment, index) => {
                        return (
                            <>
                                <div className="parent" style={{width:"inherit"}}>
                                    <div key={index} style={{display:"flex", flexDirection:"column", flexWrap:"wrap", justifyContent:"flex-start"}} className="column-50-no-equal">
                                        <span className="">Data plății: {payment.paymentDate}</span>
                                        <span className="">Suma: {payment.amount.toFixed(2)} RON</span>
                                        <span className="">Plătită: {payment.paid ? "Da" : "Nu"}</span>
                                        {payment.paid ? <></> :
                                            <>
                                                {payment.nextPayment ? <span className="">Plată programată: Da </span>: <></>}
                                                {payment.overdue ? <span className="" style={{color:"red"}}>Plată restantă: Da </span>: <></>}
                                                {payment.pendingPayment ? <span className="" style={{color:"red"}}>Plată in procesare </span>: <></>}
                                            </>
                                        }
                                    </div>
                                    <div className="column-50-no-equal">
                                        <div>
                                            {(payment.overdue || payment.nextPayment) && !showPaymentModal && !payment.pendingPayment ? <Button variant="danger" onClick={() => {selectPaymentAndShowModal(payment.id)}}>Plătește acum</Button> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                  })}
                  </Carousel>
                  </div>
              </div>
          </div>

          <Modal
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered show={showPaymentModal} onHide={handleClose}
          >
              <Modal.Header closeButton>
                  <Modal.Title>Alege cardul de plată</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable">

                  {cards && cards.userCards.map((card, index) => {
                      return (
                          <>
                              <div className="parent"
                                   style={{border: "solid, 2px", borderRadius: "30px", justifyContent: "space-evenly", marginBottom:"5px"}}>

                                  <input
                                      type="radio"
                                      name="selectedCard"
                                      value={card.id}
                                      checked={selectedCardId === card.id}
                                      onChange={() => setSelectedCardId(card.id)}
                                  />
                                  <CardDetails card={card} includeMain={false}/>
                              </div>
                          </>
                      )
                          ;
                  })}
                  <div className="parent"
                       style={{border: "solid, 2px", borderRadius: "30px", justifyContent: "space-evenly",  marginBottom:"5px"}}>

                      <input
                          type="radio"
                          name="selectedCard"
                          value={undefined}
                          checked={selectedCardId === undefined}
                          onChange={() => setSelectedCardId(undefined)}
                      />
                      <Typography>Plată cu card nou</Typography>
                  </div>

              </Modal.Body>
              <Modal.Footer>
                  {selectedCardId === undefined ?
                      <FormControlLabel
                          label="Păstrează cardul pentru plăți viitoare"
                          control={
                              <Checkbox
                                  checked={saveCard}
                                  onChange={toggleSaveCard}
                              />
                          }
                      />
                      :
                      <></>
                  }

                  <Button className="button button-contact" onClick={() => triggerPayment()}>Plătește</Button>
              </Modal.Footer>
          </Modal>

          <Modal
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered show={showBillingModal} onHide={handleCloseBilling}
          >
              <Modal.Header closeButton>
                  <Modal.Title>Înainte de a efectua o plată, trebuie să setezi adresa de facturare</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable">
                  <BillingAddressHandler userBillingAddress={{ address: "", firstName: "", lastName: "", email: "", phone: "", city: "", county: "", country: "", postCode: "" }} setShowModal={setShowBillingModal} setUpdate={props.setUpdate}/>
              </Modal.Body>
              <Modal.Footer>

              </Modal.Footer>
          </Modal>
      </>
    );
}

export default ProfileSubscriptions;
