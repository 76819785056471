import React, {useCallback, useEffect, useState} from "react";
import {getBillingAddress} from "../../services/userService";
import {UserProfile} from "../../services/model/UserProfile";
import {BillingAddress, PaymentMethod} from "../../services/model/Payments";
import BillingAddressHandler from "./BillingAddressHandler";
import BillingAddressStaticShower from "./BillingAddressStaticShower";
import UserCardsShower from "./UserCardsShower";
import {getPaymentMethods} from "../../services/paymentsService";

type Props = {
    userDetails: UserProfile
    parentUpdate: boolean
    expanded?: string | false
}

function PaymentMethods(props : Props)  {

    const [billingAddress, setBillingAddress] = useState<BillingAddress>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        county: '',
        city: '',
        address: '',
        postCode: '',
    });
    const [cards, setCards] = useState<PaymentMethod>();
    const [expandBilling, setExpandBilling] = useState(false);
    const [update, setUpdate] = useState(true);
    const getData = useCallback(async () => {
        try {
            const userBillingAddress = await getBillingAddress();
            setBillingAddress(userBillingAddress.data);

            const paymentMethods = await getPaymentMethods();
            let data = paymentMethods.data;
            if (data !== undefined && data.length > 0 && data[0] !== undefined ) {
              setCards(data[0]);
            }
        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        if (update || props.parentUpdate) {
            getData();
        }
        setUpdate(false);
    }, [update, props.parentUpdate])

    function getCurrentBillingAddress() {
        return billingAddress;
    }

    function getCurrentCards() {
        return cards;
    }

    return (
        <>
            <div className="info-topic2" >
                <div style={{padding:"20px"}}>
                    {expandBilling || getCurrentBillingAddress().email === '' ?
                        <BillingAddressHandler userBillingAddress={getCurrentBillingAddress()} update={update} setExpand={setExpandBilling} setUpdate={setUpdate}/>
                        :
                        <BillingAddressStaticShower billingAddress={getCurrentBillingAddress()} setExpand={setExpandBilling}/>
                    }
                    <UserCardsShower userDetails={props.userDetails} billingAddress={getCurrentBillingAddress()} cards={getCurrentCards()} update={update} setUpdate={setUpdate} expanded={props.expanded} />
                </div>
            </div>


        </>
    );
}
export default PaymentMethods;
