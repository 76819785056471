import '../App.css';
import {UserExchange} from "../services/model/GroupExchangeResponse";

type Props = {
    startTime: number,
    endTime: number,
    user: UserExchange,
}

const ExchangeDateElement = (props : Props) => {
    return (
        <>
            {props.startTime !== 0 ?
                <div>
                    <span style={{fontWeight: (props.startTime <= Date.now() && Date.now() <= props.endTime) ? 'bold' : 'normal'}}>
                        {new Date(props.startTime).toLocaleDateString("ro", {
                            day: "2-digit",
                            month: "short"
                        })} - {new Date(props.endTime).toLocaleDateString("ro", {
                        day: "2-digit",
                        month: "short"
                    })} : {(props.user?.firstName && props.user?.lastName) ? props.user.firstName + " " + props.user.lastName : "în biblioteca grădiniței"}
                    </span>
                </div>
                :
                <span></span>
            }
        </>
    );
}

export default ExchangeDateElement;