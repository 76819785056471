import {Outlet} from "react-router-dom";
import {Box} from "@mui/material";
import AppHeader from "../AppHeader";
import AppFooter from "../AppFooter";
import Divider from "@mui/material/Divider";
import React from "react";

const MainLayout = () => {
  return (
      <Box component="main">
          <AppHeader/>
          <Outlet/>
          <div className="parent" style={{alignItems: "flex-start", justifyContent: "center"}}>
              <div style={{width: "90%"}}>
                  <Divider variant="middle"/>
              </div>
          </div>
          <AppFooter/>
      </Box>
  );
};

export default MainLayout;